//
// archive events page
//
.events-archive {

  h4 {
    margin-bottom: 5px;
    @include font-size(18px);
    font-weight: 700;
    color: $red;
  }
}

//
// single events page
//
.get-back {
  display: block;
  color: $red;
  line-height: 1.1;
  text-align: right;
  text-transform: uppercase;
}

.event-title {
  margin-bottom: 1.5rem;
  color: $blue;
}

.event-meta {
  margin-bottom: 1.5rem;
  @extend .nolist;
  @include font-size(20px);
}

.event-link {
  @include font-size(20px);
  font-weight: 700;
  color: $red;

  & a {
    color: $red;

    &:hover {
      color: $blue;
    }
  }
}

//
// blog archive
//

article.regular-archive {
  header {

    .post-thumb {
      // height: calc(66.25% - 1px);
      aspect-ratio: 4 / 3;
      object-fit: cover;
      object-position: 50% 0;
    }

    img {
      margin-bottom: 0.875rem;
    }
  }
}

//
// single post
//
.single-post-wrap {
  margin-top: 1.75rem;
  
  @include media-breakpoint-up(lg){
    margin-top: 50px;
  }
}

article.single-post {
  padding: 1.5rem;
  background-color: $white;
}

h1.entry-title {
  margin-bottom: 25px;
  color: $blue;
}