header.banner {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $navigation-height-sm;
  background-color: $black;
  transition: all 0.2s ease-in-out;
  z-index: 996;
  
  @include media-breakpoint-up(lg){
    height: $navigation-height;
    min-height: 68px;
  }
  
  @include media-breakpoint-up(xl){
    min-height: 70px;
  }

  &.scrolled {
    @include media-breakpoint-up(lg){
      // height: $navigation-height;
      // min-height: 68px;
      height: $navigation-height-scrolled;
    }
    
    // @include media-breakpoint-up(xl){
    //   min-height: 70px;
    // } 
  }

  // logo
  .brand {
    margin: 0 0 0 15px;
    width: 280px;
    height: 39px;
    text-indent: -999em;
    background-image: url(../images/push-democracy-forward.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    z-index: 101;
    transition: all 0.2s ease-in-out;
    
    @include media-breakpoint-up(md) {
      width: 400px;
      height: 56px;
    }
    
    @include media-breakpoint-up(xl) {
      margin: 0 0 0 8.3333333%;
      // width: 511px;
      // height: 71px;
    }

    &.show {
      // background-image: url(../images/duckworth-custom-homes-dark.svg);
      z-index: 999;
    }
  }

  &.scrolled {
    box-shadow: 0 0 15px rgba($black, 0.5);
  }
}

.navbar-collapse {
  @include media-breakpoint-up(lg){
    flex-basis: unset;
    flex-grow: unset;
    align-items: unset;
  }
}

.navbar-collapse.collapse {
  display: none;
  height: 0;
  overflow: visible;
  
  @include media-breakpoint-up(lg) {
    display: flex;
    height: auto;
    overflow: visible;
  }
}

.navbar-collapse.collapsing {
  display: block!important;
  background-color:transparent;
}

.nav-primary {
  transition: all 0.2s ease-in-out;

  @include media-breakpoint-up(lg){
    position: relative;
    margin-right: 15px;
    height: auto;
    background-color: transparent;
  }

  @include media-breakpoint-up(xl){
    margin-right: 8.3333333%;
  }

  ul#menu-main-navigation {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    transform: translateY( (($navigation-height-sm / 4 ) * -1) );

    li.menu-item {
      margin: 0 0 20px;
      padding: 0 0 20px;
      border-bottom: 2px solid $gray;
      text-align: center;
      width: auto;

      &:last-of-type {
        margin: 0;
        padding: 0;
        border-bottom: 0;
      }

      a {
        color: $white;
        @include font-size(28);
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 1px;
        font-weight: 700;
        display: block;
        
        @include media-breakpoint-up(lg){
          line-height: $navigation-height;
        }

        &:hover {
          color: $red;
        }
      }

      &.menu-donate {

      }

      .sub-menu {
        margin:0;
        padding: 0;
        list-style: none;
        
        @include media-breakpoint-up(lg){
          display: block;
          position: absolute;
          top: $navigation-height;
          left: -25px; // li padding below
          height: 0;
          z-index: 999;
          opacity:0; // http://jsfiddle.net/hashem/9dsGP/2/
          width: auto;
          background-color: $white;
          box-shadow: 0 0 15px rgba($black,0.4);
          @include transition(all .25s ease-in-out);
        }

        li {
          margin:0;
          padding: 7px 0;
          height: auto;
          white-space: nowrap;
          
          @include media-breakpoint-up(lg){
            padding: 10px 25px;
          }

          a {
            @include font-size(16px);
            text-transform: capitalize;
            color: $black;
            line-height: 1;

            &:hover {
              color: $blue;
            }
          }
        }
      } // END .sub-menu

      @include media-breakpoint-up(lg){
        &:hover .sub-menu {
          display: block;
          opacity:1;
          height:auto;
        }
      }
    }

    @include media-breakpoint-up(lg){
    
      flex-flow: row nowrap;
      transform: translateY(0);
      
      li.menu-item {
        margin: 0;
        padding: 0;
        // border-right: 2px solid $gray;
        border-bottom: 0;
        width: auto;
        display: flex;
        align-items: center;
        position: relative;
        text-align: left;

        &:last-of-type {
          padding: 0;
          // border-right: 0 solid $gray;  
        }

        a {
          padding: 0 20px 0;
          @include font-size(16);
        }
      }
    }
  }
  // active mobile menu
  &.show {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh!important;
    background-color: rgba($black,0.98);
    display: flex!important;
    justify-content: center;
    align-items: center;
    z-index: 998;

    ul#menu-main-navigation {
    
      li.menu-item {
        
        a {
          color: $white !important;
        }

        li {
          border-bottom: 0;
        }
      }
    }
  }
}

.navbar-toggle {
  position: relative;
  margin: 0 15px 0 0;
  background-color: transparent;
  background-image: none;
  border: 0 solid transparent;
  border-radius: 0;
  z-index: 999;
  
  @include media-breakpoint-up(md) {
    padding: 10px;
    display: block;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
  color: $white;
}

.navbar-default .navbar-toggle:focus span.glyphicon, .navbar-default .navbar-toggle:hover span.glyphicon {
  color: $white;
}

// navburger
.hamburger {
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  overflow: visible;

  // need to add height so it centers in flex layout
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;

  &:active, 
  &:focus {
  outline: 0;
  border: 0;
  }
  
  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.hamburger.is-active {

  .hamburger-inner {

    &,
    &::before,
    &::after {
      background-color: $white;
    }
  }
}

/*
 * Squeeze
 */
.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.14s ease, opacity 0.1s ease;
    }

    &::after {
      transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.14s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease, opacity 0.1s 0.14s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

/* WTF?!?
.home header.active {
  // display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: $black;
  transition: all 0.2s ease-in-out;

  // logo
  .brand {
    margin: 35px auto 50px;
    width: 280px;
    height: 64px;
    background-image: url(../images/garwood-architecture-vrt.svg);
    
    @include media-breakpoint-up(md) {
      width: 400px;
      height: 92px;
    }
    
    @include media-breakpoint-up(xl) {
      width: 450px;
      height: 104px;
    }
  }

  // display navbar on small screens, hamburger override
  .navbar-collapse.collapse {
    display: flex;
    height: auto;
    overflow: visible;
  }

  // button
  .navbar-toggle {
    display: none;
  }  

  .nav-primary {
    margin: 0 0 35px;
    height: auto;
    background-color: transparent;

    ul#menu-main-navigation {
      flex-flow: row nowrap;
      transform: translateY(0);

      li.menu-item {
        margin: 0 20px 0 0;
        padding: 0 20px 0 0;
        border-right: 2px solid $gray;
        border-bottom: 0;
        text-align: left;
        width: auto;

        &:last-of-type {
          border-right: 0 solid $gray;
          margin: 0;
          padding: 0;
        }

        a {
            @include font-size(14);
          
          @include media-breakpoint-up(lg) {
            @include font-size(16);
          }
        }
      }
    }
  }
}
*/