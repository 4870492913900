.btn {
  @include font-size(15px);
  text-transform: uppercase;
  border-radius: 0;

  &.btn-primary {
    color: $white;
  }

  &:hover {
    background-color: $black;
    color: $white;
  }
}

.btn-red {
  background-color: $red;
  color: $white;
}