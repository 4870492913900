.the-sidebar {

  ul {
    list-style: none;
    margin: 0 0 0.5rem;
    padding: 0;

    li {
      margin: 0 0 1rem;
      padding: 0 0 1rem;
      border-bottom: 1px solid red;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  h3 {
    margin-bottom: 2rem;
    font-family: $font-stack;
    @include font-size(20px);
    font-weight: 700;
    color: $black;
  }

  h4 {
    margin-bottom: 0;
    @include font-size(24px);
    color: $blue;

    a {
      color: $blue;
    }
  }

  .more-link {
    @include font-size(22px);
    color: $red;
  }

  .back-link {
    text-align: right;
    
    a {
      @include font-size(16px);
      text-transform: uppercase;
      color: $red;

      &:hover {
        color: $blue;
      }
    }
  }
}