// global page styles
.page-header {
  margin: 1.75rem 0;
  text-align: center;
  
  @include media-breakpoint-up(lg){
    margin: 50px 0;
  }

  h1 {
    text-transform: uppercase;
    margin: 0;
    color: $blue;
  }
}

.page-banner {
  @extend .full-width;
  margin-bottom: 50px;
  min-height: 55vh;
  background-position: 50% 30%;
  background-size: cover;
  position: relative;

  .banner-content {
    // padding: 15px 25px 25px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 55vh;
    position: absolute;
    z-index: 2;

    // @include media-breakpoint-up(lg){
    //   padding: 2vw 3.15vw 2.1vw;
    //   padding-left: 8.33333333%;
    // }

    h1 {
      margin: 0;
      @include font-size(46px);
      text-transform: uppercase;
      color: $white;
    }

    h2 {
      margin: 0;
      @include font-size(46px);
      // font-family: $sans-serif;
      color: $white;

    }
  }
}

// overlay for images, parent container must have position:relative children must have z-index > 1
.oscuro {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba($black,0.4);
  // background: linear-gradient( 15deg, rgba($blue, 0.8) 25%, rgba($blue, 0) );
  z-index: 1;
}

//
// home page
//
.home .content-section {
  margin-top: 3rem;
  margin-bottom: 3rem;
  
  @include media-breakpoint-up(lg){
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  
  @include media-breakpoint-up(xl){
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.home-slides {
  margin-top: 0;
  margin-bottom: 100px;

  .carousel-item {
    height: 0;
    padding-bottom: 40vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    @include media-breakpoint-up(lg){
      padding-bottom: (100vh - $navigation-height);
    }
    // img {
    //   margin: 0 auto;

    //   @include media-breakpoint-up(lg){
    //     max-height: 75vh !important;
    //     width: auto !important;
    //   }
    // }
  }

  .carousel-indicators {
    margin: 0;
    padding: 0;
    bottom: -55px;

    li {
      margin: 0 5px;
      width: 13px;
      height: 13px;
      background-color: $blue;
      border-radius: 50%;
      opacity: 1;
      transition: all 0.5s ease-in-out;

      &.active {
        background-color: $red;
      }
    }
  }
}

.home-page-content {

  p {
    color: $black;
  }
}

.home {

  .big-link {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: $blue;

    h3 {
      margin: 0;
      @include font-size(48px);
      font-weight: 700;
      color: $white;
    }

    .big-arrow {
      text-align: right;
      @include transition(all 0.2s ease-in-out);

      &:hover {
        transform: translateX(25px);
      }


      img {
        max-width: 100px !important;
      }
    }
  }
}

.big-logo {
  margin-top: 3rem;
  margin-bottom: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: $red;

  .big-logo--content {
    text-align: center;

    img {
      max-width: 350px !important;
    }
  }
}

.posts-grid,
.events-grid {
  display: grid;
  gap: 1rem;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(lg){
    grid-template-columns: repeat(3, 1fr);
  }

  article {
    padding: 1rem;
    background-color: $white;
  }
}

.home-posts-grid {

  .post-thumb {
    margin: -1rem  -1rem 1.5rem;
    width: calc(100% + 2rem);
    max-width: unset !important;
  }

  article:not(:first-child) .post-thumb {
    // height: calc(66.25% - 1px);
    aspect-ratio: 4 / 3;
    object-fit: cover;
    object-position: 50% 0;
  }

  @include media-breakpoint-up(lg){
    article {

      &:first-of-type {
        grid-column: 1 / 4;
        grid-row: 1 / 3;
      }
    }
  }
}

.events-grid {

  @include media-breakpoint-up(lg){
    grid-template-columns: repeat(2, 1fr);
  }
}

//
// about page
//
.about,
.page-id-7 {

  section {
    position: relative;
    overflow: visible;

    h3 {
      @include font-size(18);
      text-transform: uppercase;
    }
  }

  $container-width-lg: 50vw;
  $container-width-xl: 30vw;
  $container-offset: -20%;
  $container-padding: 5vh;
  $container-position: 15vw;

  section.intro-content {
    padding: 0;

    .intro-photo {
      opacity: 0.5;
    }

    .inset {
      padding: 25px;
      background-color: $white;

      @include media-breakpoint-up(lg){
        padding: $container-padding;
        position: absolute;
        bottom: $container-offset; // 
        right: $container-position;
        width: $container-width-lg;
        z-index: 15;
      }

      @include media-breakpoint-up(xl){
        width: $container-width-xl;
      }
    }
  }
}