.home .footer-contact,
.footer-contact {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 0;
  background-color: $blue;

  label {
    color: $white;
  }
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0 0 1.5rem;
  padding: 0;

  li {
    margin: 0 15px 0 0;
    padding: 0;

    a {
      @include font-size(20);
      color: $red;

      &:hover {
        color: $blue;
      }
    }
  }
}

footer.content-info {
  padding: 50px 0 15px;
  background-color: $white;

  @include media-breakpoint-up(xl){
    padding-top: 75px;
  }

  .footer-content {
    // margin-bottom: 75px;
    padding: 55px 0 25px;
    position: relative;
    text-align: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width:100%;
      height: 30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 0;
    }

    // &::before {
    //   top:0;
    //   background-image: url(../images/curls.svg);
    // }

    // &::after {
    //   bottom:0;
    //   background-image: url(../images/curls.svg);
    //   transform: rotate(180deg);
    // }

    h3,
    h4,
    h5 {
      letter-spacing: 1px;
    }

    h4 {
      margin-bottom: 25px;
      @include font-size(15);
      font-style: italic;
      color: $blue;
    }

    h5 {
      margin-bottom: 0px;
      @include font-size(14);
      // color: $white;
    }
  }

  .footer-logo {
    // margin-top: 2rem;
    // margin-bottom: 2rem; 
    display: flex;

    h3 {
      // margin-bottom: 5px;
      width: 300px;
      height: 185px;
      text-indent: -999rem;
      background-image: url(../images/push-democracy-forward-stacked.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

#menu-footer-navigation {
  margin: 0;
  padding: 0;
  flex-flow: column nowrap;

  li {

    a {
      display: block;
      padding: 0 0 1.5rem;
      @include font-size(20px);
      line-height: 1.3;
      font-weight: 700;
      color: $red;
      text-transform: uppercase;
    }
  }
}

.footer-supporters {
  img {
    max-height: 75px;
    width: auto; 
  }
}

.contact-info {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0 0 25px;
    color: $blue;
    text-align: left;
    display: flex;
    align-items: center;

    &,
    // span,
    a {
      line-height: 1.3;
      font-weight: 700;
    }

    span {
      margin-right: 15px;
      display: inline-block;
      min-width: 34px;
      @include font-size(28px);
      text-align: center;
    }

    a {
      color: $blue;
      // font-weight: bold;
      @include font-size(18px);
    }
  }
}

.twitter-embed {
  overflow-y: scroll;
}

.legal {
  margin-top: 25px;
  text-align: center;
  @include font-size(14);
  color: $gray;
}